import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProductGroups } from 'src/actions/productGroup'
import {
  CCard,
  CCollapse,
  CFormInput,
  CFormLabel,
  CListGroup,
  CListGroupItem,
  CCol,
  CFormText,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { FaCircleXmark } from 'react-icons/fa6'
import FallbackImage from 'src/components/FallbackImage'

const ProductGroupDropDown = ({
  cols = 4,
  payload,
  setPayload,
  valueName = 'group_id',
  title = 'Product Groups',
  searchName = '',
  error,
  top = false,
  regionFilter = false,
}) => {
  const dispatch = useDispatch()
  const [isActive, setIsActive] = useState(false)
  const productGroups = useSelector((state) => state.productGroups)
  const auth = useSelector((state) => state.auth.token)
  const [result, setResult] = useState(productGroups)
  const [search, setSearch] = useState('')

  const handleChangeInput = (searchKey) => {
    setSearch(searchKey)
  }

  const handleOnclick = (resultGroup) => {
    setPayload((prevInfo) => {
      return {
        ...prevInfo,
        [valueName]: resultGroup.id,
      }
    })
    setSearch(`${resultGroup.name}`)
    setIsActive(false)
  }

  const handleClear = () => {
    setSearch('')
    setIsActive(false)
  }

  useEffect(() => {
    const params =
      regionFilter && 'sale_region' in payload
        ? {
            sale_region: payload?.sale_region,
            bonus_type_id: payload?.bonus_type_id,
          }
        : { bonus_type_id: payload?.bonus_type_id }
    dispatch(fetchProductGroups(auth, params)).catch((error) => {
      alert(error)
    })
  }, [payload?.bonus_type_id, payload?.sale_region])

  useEffect(() => {
    if (search.length > 0) {
      setResult(() => {
        const filteredResult = productGroups.filter((item) =>
          item.name.toLowerCase().includes(search.toLowerCase()),
        )
        return filteredResult
      })
      return
    }
    setPayload((prevInfo) => {
      return {
        ...prevInfo,
        [valueName]: '',
      }
    })
    setResult(productGroups)
  }, [productGroups, search])

  useEffect(() => {
    setSearch(searchName)
  }, [searchName])

  return (
    <CCol xs={12} md={cols}>
      <div className="mb-3">
        <CFormLabel>{title}</CFormLabel>
        <div className="position-relative content">
          {isActive && (
            <CCard
              className="position-absolute text-nowrap w-100"
              style={{
                maxHeight: '200px',
                overflowY: 'auto',
                zIndex: '10',
                ...(top ? { bottom: '37px' } : { top: '37px' }),
                minWidth: 'max-content',
              }}
            >
              <CCollapse visible={true}>
                <CListGroup>
                  {result?.map((item, index) => (
                    <CListGroupItem
                      key={index}
                      className="light-gray-hover d-flex"
                      onClick={() => handleOnclick(item)}
                    >
                      {Array.isArray(item?.products)
                        ? item.products.map((product, productIndex) => (
                            <div key={productIndex} className="d-flex align-items-center">
                              <div className="agent_avatar">
                                <FallbackImage
                                  path={product?.thumbnail || ''}
                                  title={product?.name || ''}
                                  clickable={false}
                                />
                              </div>
                            </div>
                          ))
                        : null}

                      <div
                        className="d-flex align-items-center ms-2"
                        style={{ width: '80%', fontSize: '14px' }}
                      >
                        {/* {item?.name || ''} */}
                        {payload?.bonus_type_id === 2
                          ? `${item?.name || ''} ${
                              item?.sale_region ? `(${item?.sale_region})` : ''
                            }`
                          : item?.name || ''}
                      </div>
                    </CListGroupItem>
                  ))}
                </CListGroup>
              </CCollapse>
            </CCard>
          )}

          <CFormInput
            type="text"
            value={search}
            onChange={(e) => handleChangeInput(e.target.value)}
            placeholder={'Select Product Group'}
            onFocus={() => setIsActive(true)}
            onBlur={() =>
              setTimeout(() => {
                setIsActive(false)
              }, 400)
            }
          />
          <FaCircleXmark
            className=" position-absolute"
            style={{ right: '8px', top: '30%', cursor: 'pointer' }}
            onClick={handleClear}
          />
        </div>
        {error && (
          <CFormText id="thumbnail" className="text-danger">
            {error}
          </CFormText>
        )}
      </div>
    </CCol>
  )
}

ProductGroupDropDown.propTypes = {
  cols: PropTypes.number,
  payload: PropTypes.func.isRequired,
  setPayload: PropTypes.func.isRequired,
  valueName: PropTypes.string,
  title: PropTypes.node,
  searchName: PropTypes.string,
  error: PropTypes.string,
  top: PropTypes.bool,
  regionFilter: PropTypes.bool,
}

export default ProductGroupDropDown
