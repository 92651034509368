import React from 'react'
import PropTypes from 'prop-types'
import { CCol, CForm, CRow } from '@coreui/react'
import RegionDropDown from 'src/components/SaleRegions/RegionDropDown'
import ProductGroupDropDown from 'src/components/Common/DropDown/ProductGroupDropDown'

const ThreeMonthFrameFilter = ({ payload, setPayload }) => {
  return (
    <CForm>
      <CRow>
        <RegionDropDown payload={payload} setPayload={setPayload} />
        <ProductGroupDropDown payload={payload} setPayload={setPayload}></ProductGroupDropDown>
        {/* <CCol xs={12} md={4}>
          <ProductGroupDropDown
            payload={payload}
            setPayload={setPayload}
            regionFilter={true}
            payloadKey="group_id"
          />
        </CCol> */}
      </CRow>
    </CForm>
  )
}

ThreeMonthFrameFilter.propTypes = {
  payload: PropTypes.object.isRequired,
  setPayload: PropTypes.func.isRequired,
}
export default ThreeMonthFrameFilter
